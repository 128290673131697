import React from 'react';
import { currentTalk, roomData } from '../sitedata.js';

////////////////////////////////////
///////// Renders Stream ///////////
////////////////////////////////////
export default class StreamContainer extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      currentTalk: null,
      talk_data: {
        title: '',
        id: null,
        speakers: [],
        moderators: [],
        description: '',
        organisation: null
      },
      submitStatus: ''
    }
  }
  
  // Checks if rendered talk is the same as current (active) talk 
  // via a small json object via an API.
  checkTalkId(){
    let roomUrl = `/talk-data.json`;
    fetch( roomUrl ).then(response => response.json())
    .then( roomData => {      
      if( this.state.currentTalk !== roomData.current_talk_data.id){
        this.setState({
          currentTalk: roomData.current_talk_data.id
        });
        
        this.getNewTalk(roomData.current_talk_data.id);
      }
    }).catch( e => {
      console.log(e);
    });
  }
  
  // Gets new talk data. To prevent additional strain on the server
  // this data is stored in the HTML page as roomData -> 
  // all text data for the whole day was added to an object and then
  // aggressively cached in WP. (we had a strict
  // content freeze to prevent mismatched data during the day)
  getNewTalk(id){
    // roomData should always exist, but can be empty
    if( typeof roomData !== "undefined" ){
      // Content to show if no talk is happening.
      let talk = {
        title: 'Nothing happening at the moment',
        id: null,
        speakers: [],
        moderators: [],
        description: '',
        organisation: '',
        message: 'Nothing happening at the moment',
      };
      // Content of current talk. 
      for( let a = 0; a < roomData.talks.length; a++ ){
        if( roomData.talks[a].id == id ){
          // Once again, this data is fetched an object in the HTML page.
          talk = roomData.talks[a];
        }
      }

      this.setState({
        talk_data: talk
      });
    }
  }

  componentDidMount() {
    this.checkTalkId();
    this.talkTimer = setInterval(
      () => {
        this.checkTalkId()
      },
      40000
    );
  }

  componentWillUnmount() {
    clearInterval(this.talkTimer);
  }

  render(){
    if( currentTalk ){
      // Renders moderators in page
      let moderators = null;
      if( this.state.talk_data.moderators.length ){
        moderators = this.state.talk_data.moderators.map( moderator =>
          (<article className="speaker">
            <h4 className="speaker__title stream-speaker__title"><a href={moderator.permalink}>{moderator.name}</a></h4>
            <p className="speaker__description">{moderator.description}</p>
            <img className="speaker__image" src={moderator.image} alt=""/>
          </article>)
        );
      }

      // Renders speakers in page
      let speakers = null;
      let speakersString = "";
      if( this.state.talk_data.speakers.length ){
        speakers = this.state.talk_data.speakers.map( speaker =>
          (<article className="speaker">
            <h4 className="speaker__title stream-speaker__title"><a href={speaker.permalink}>{speaker.name}</a></h4>
            <p className="speaker__description">{speaker.description}</p>
            <img className="speaker__image" src={speaker.image} alt=""/>
          </article>)
        );
  
        this.state.talk_data.speakers.forEach( speaker => {
          speakersString += `<li class="inline-speaker">${speaker.name}</li>`;
        })
      }

      // Talk title
      document.querySelector('.stream__title').innerHTML = this.state.talk_data.title;

      // Talk organiser
      document.querySelector('.stream__organiser').innerHTML = this.state.talk_data.organisation;

      // Shows organiser block
      document.querySelector('.stream-org').style.display = "block";

      return (
        <div className="">
          <StreamDescription description={this.state.talk_data.description}/>
          <h3 className="single__subtitle">Moderators</h3>
          {moderators}
          <h3 className="single__subtitle">Speakers</h3>
          {speakers}
        </div>
      );
    }else{
      document.querySelector('.stream-org').style.display = "none";
      document.querySelector('.stream__title').innerHTML = this.state.talk_data.message;
      return false;
    }
  }
}

class StreamDescription extends React.Component{
  constructor(props){
    super(props);
  }

  render(){
    // Adds break tags for newlines
    let descriptionLines = this.props.description.replace(/(?:\r\n|\r|\n)/g, '<br>').split('<br>');
    return (
      <div className="stream__description">
        <h2 className="single__subtitle">About this session</h2>
        <div>{descriptionLines.map(line => <React.Fragment>{line}<br/></React.Fragment>)}</div>
      </div>
    );
  }
}