import React from 'react';
import { wpApiSettings, currentTalk, commentCount } from '../sitedata.js';

////////////////////////////////////
//////// Renders Comments //////////
////////////////////////////////////
export default class CommentsContainer extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      questionsAsked: [],
      showPopup: false
    }
    this.questionHandler = this.questionHandler.bind(this);
    this.showMessage = this.showMessage.bind(this);
    this.hideMessage = this.hideMessage.bind(this);
  }
  
  // Handles form input
  questionHandler(e){
    this.state.questionsAsked.push({
        "id": e,
        "seen": false
    });
  }

  // Handles invitation to join on stream.
  // This is non functional in this version of the code.
  showMessage(id){
    let items = [...this.state.questionsAsked];
    for(let questionCounter = 0; questionCounter < items.length; questionCounter++){
      if( items[questionCounter].id === id ){
        items[questionCounter].seen = true;
        this.setState({
          questionsAsked: items,
          showPopup: true,
        })
        break;
      }
    }
  }
  
  hideMessage(){
    this.setState({
      showPopup: false,
    })
  }
  
  render(){
    let popup = null;
    if( this.state.showPopup ){
      popup = (
        <div className="message-popup">
          <div className="message-popup__content">
            <p>Your question has been posted. Please join us on the following link:</p>
            <a href="" className="button button--join-stream" rel="noopener">Join us on the stream</a>
          </div>
        </div>
      );
    }

    let gatherLink = null;
    if( wpApiSettings['gather-link'] ){
      gatherLink = (
        <a href={wpApiSettings['gather-link']} className="button gather-link" rel="noopener">Join us on Gather</a>
      );
    }

    return (
      <div className="questions">
        { gatherLink }
        { popup }
        <GetComments questionsAsked={this.state.questionsAsked} showMessage={this.showMessage}/>
        <CommentForm questionHandler={this.questionHandler}/>
      </div>
    )
  }
}

class GetComments extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      commentCount: 0,
      commentData: [],
      commentsLoaded: true,
      commentsDisabled: false
    }
  }

  getComments(){
    // Shows default text if no comments are present
    if( currentTalk ){
      this.setState({
        commentsDisabled: false
      })

      // The talk data API gets polled every 40s, if the length
      // of the property talkData.current_talk_data.questions did
      // not change, no additional requests are made, otherwise
      // comment-data gets loaded, which contains all comments
      // of the current talk.
      if( commentCount != this.state.commentCount){
        let commentUrl = '/comment-data.json';
        fetch( commentUrl ).then(response => response.json())
        .then( commentData => {
          this.setState({
            commentCount: commentCount,
            commentData: commentData,
            commentsLoaded: true,
            questionsAsked: this.props.questionsAsked,
            commentsDisabled: false
          });
        }).catch( e => {
          console.log(e);
        });
      }
    }else{
      this.setState({
        commentsDisabled: true
      })
    }
  }

  // Checks every 10s. This is a higher frequency
  // than the talk data timer as we can't guarantee
  // that visitors will receive the talk data within 
  // 40s due to network or server load constraints.
  // (If set to 40s it would be possible that comments
  // will only get refreshed every 40+40 = 80s)
  componentDidMount() {
    this.getComments();
    this.timerID = setInterval(
      () => this.getComments(),
      10000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }
  
  render() {
    let commentContent = (<div className="comment-box"><div className="question__element">Loading questions...</div></div>);

    if( this.state.commentsDisabled ){
      commentContent = (<div className="comment-box"><div className="question__element">There is no Q&amp;A available at the moment.</div></div>);
    }else{
      commentContent = (<div className="comment-box"><div className="question__element">No questions asked at this time. Be the first!</div></div>);
    }
    let commentList = null;
    
    for( let commentCount = 0; commentCount < this.state.commentData.length; commentCount++ ){
      let currentCommentId = this.state.commentData[commentCount].author_url.replace('http:\/\/', '');
      
      for( let askedCount = 0; askedCount < this.props.questionsAsked.length; askedCount++ ){
        if( this.props.questionsAsked[askedCount].id === currentCommentId && !this.props.questionsAsked[askedCount].seen){
          this.props.showMessage(currentCommentId);
        }
      }
    }
    if( this.state.commentData.length ){
      commentList = this.state.commentData.map((comment, index) =>
        (<li key={index} id={comment.id} className="question__element">
          <strong>{comment.author_name}</strong><br/>
          {comment.plaintext}
        </li>)
      );
    }

    if( this.state.commentData.length ){
      commentContent = (
        <ul className="comment-box">
          {commentList}
        </ul>
      )
    }
    return (
      <div className="questions-box">
        {commentContent}
      </div>);
  }
}

class CommentForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      questionValue: '',
      submitStatus: '',
      questionName: '',
      questionsAsked: []
    }

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e){
    this.setState({
      questionValue: e.target.value,
    })
  }

  handleNameChange(e){
    this.setState({
      questionName: e.target.value,
    })
  }

  handleSubmit(e){
    e.preventDefault()
    let commentUrl = `${wpApiSettings.root}wp/v2/comments`;

    let status = '';

    if( this.state.questionName.length <= 0 ){
      this.setState({
        submitStatus: "Please enter your name."
      });
      return;
    }

    if( this.state.questionValue.length <= 0 ){
      this.setState({
        submitStatus: "Please enter a message."
      });
      return;
    }

    let qId = (Math.floor(Math.random()*100000)*10000).toString();

    this.props.questionHandler(qId);

    let data = {
      post: currentTalk,
      author_name: this.state.questionName,
      content: this.state.questionValue,
      author_url: qId,
    };

    // Posts comment
    fetch( commentUrl, {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-WP-Nonce': wpApiSettings.nonce
      },
      body: JSON.stringify(data)
    } ).then(
      
      response => {
        if( response.ok ){
          status = "Your question has been submitted and will appear shortly.";
          this.setState({
            submitStatus: status,
            questionValue: '',
            questionName: '',
          });
        }else{
          status = `We couldn't receive your message. Please try again or send your message to info@cpdp.org.`;
          
          this.setState({
            submitStatus: status,
          });
        }

        return response.json();
      }
    )
    .then(
      response =>
      {
        //console.log(response.message)
      }
    ).catch( e => {
      console.log(e);
    });
  }

  render() {
    return (
      <div>
        <p className="submit-status">{this.state.submitStatus}</p>
        <form>
          <label htmlFor="name">Your name:<br/></label>
          <input type="text" id="name" onChange={this.handleNameChange} value={this.state.questionName}/><br/>
          <label htmlFor="question">Your question:<br/></label>
          <textarea type="text" id="question" onChange={this.handleChange} value={this.state.questionValue}/><br/>
          <input type="submit" className="button submit-question" value="Submit" onClick={this.handleSubmit} />
        </form>
      </div>
    );
  }
}