import React from 'react';
import { wpApiSettings, currentTalk, commentCount, qId } from '../sitedata.js';

////////////////////////////////////
///////// Renders Stream ///////////
////////////////////////////////////

// Gets comments that need to be approved.
// The idea was that people could ask questions that
// would then be either approved or rejected by the 
// moderators, which would then invite the person on
// the stream to ask their question live (like passing 
// the mic in a real life conference).
// This was disabled on the second day of the event
// as people wanted to use the chat box as a more
// traditional free form chat box. Questions where then
// asked by the moderator on stream.
export default class GetPendingComments extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        commentCount: 0,
        commentData: []
      }
      this.getComments = this.getComments.bind(this);
    }
  
    getComments(){
      if( currentTalk ){
        let commentUrl = `/pending-comments.json`;
        fetch( commentUrl, {
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'X-WP-Nonce': wpApiSettings.nonce
          }
        } ).then(response => response.json())
        .then( pendingCommentData => {
          this.setState({
            commentCount: pendingCommentData.length,
            commentData: pendingCommentData,
            commentsLoaded: true
          });
        }).catch( e => {
          console.log(e);
        });
      }
    }
    
    componentDidMount(){
      this.getComments();
      this.timerID = setInterval(
        () => this.getComments(),
        10000
      );
    }
  
    render(){
      let commentList = null;
      commentList = this.state.commentData.map((comment, index) =>
        (<li key={index} id={comment.id} className="question__element">
          <strong>{comment.author_name} <span className="question__date">({comment.date.substr(11,5)})</span></strong><br/>
          {comment.plaintext}
          <AdminControls id={comment.id} qId={comment.author_url.replace('http:\/\/', '')}/>
        </li>)
      );
      return (
        <div>
          <h3>Comments to review: {this.state.commentCount}</h3>
          <ul>
            {commentList}
          </ul>
        </div>
      );
    }
  }
  
  class AdminControls extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        commentCount: 0,
        commentData: [],
        status: ''
      }
  
      this.rejectQuestion = this.rejectQuestion.bind(this);
      this.approveQuestion = this.approveQuestion.bind(this);
      this.updateQuestion = this.updateQuestion.bind(this);
    }
  
    rejectQuestion(e){
      this.updateQuestion('trash');
    }
  
    approveQuestion(e){
      this.updateQuestion('approved');
    }
  
    updateQuestion( e ){
        let commentUrl = `${wpApiSettings.root}wp/v2/comments/${this.props.id}`;
    
        let status = '';
    
        let data = {
          status: e,
        };
  
        this.setState({
          status: e
        })
    
        fetch( commentUrl, {
          method: 'post',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'X-WP-Nonce': wpApiSettings.nonce
          },
          body: JSON.stringify(data)
        } ).then(
          response => {
            if( response.ok ){
              status = "Question posted.";
            }else{
              status = "Not posted.";
            }
            this.setState({
              submitStatus: status
            });
    
            return response.json();
          }
        )
        .then(
          response =>
          {
            //console.log(response.message)
          }
        ).catch( e => {
          console.log(e);
        });
      
    }
  
    render(){
      return (
        <div className={"admin-controls admin-controls--" + this.state.status}>
          <button type="button" className="button approve-question" onClick={this.approveQuestion}>Approve</button><button type="button" className="button reject-question" onClick={this.rejectQuestion}>reject</button>
        </div>
      )
    }
  }