import logo from './logo.svg';
import './style.css';

//import {AdminControls} from './modules/adminControls';
import CommentsContainer from './modules/commentsContainer';
import GetPendingComments from './modules/getPendingComments';
import StreamContainer from './modules/streamContainer';
//import {StreamDescription} from './modules/streamDescription';

export function App() {
  return (
    <StreamContainer/>
  );
}

export function CommentsBox() {
  return (
    <CommentsContainer/>
  )
}

export function PendingComments() {
  return (
    <GetPendingComments/>
  )
}

export default App;
